import BSN from "bootstrap.native"
import Tabulator from "tabulator-tables";
import AutoNumeric from 'autonumeric';
import { ApplicationController } from "../application_controller";
import { Draggable } from 'agnostic-draggable';

export default class extends ApplicationController {
  static targets = ["table", "search", "period",
    "status", "sale_stage_ids", "product_ids", "user_ids",
    "mapping_dropdown", "mapping_checkbox", "mapping_fields",
    "exchangeRateFields", "exchangeRateInput",
    "sidebar_table", "sidebar_search", "sidebar_period",
    "sidebar_status", "sidebar_sale_stage_ids", "sidebar_product_ids", "sidebar_user_ids",
    "sidebar_current_sort", "sidebar_sort", "sidebar_current_filter", "sidebar_filter",
    "deals_modal_xl", "deal_partners_modal_md", "deal_partner_contacts_modal_md",
    "group_edit_button", "group_edit_table"]

  connect() {
    // Main table
    if (this.hasTableTarget) {
      const content_url = this.data.get("table-load-url");
      this.createTable(content_url);
    }

    if (this.hasSidebar_tableTarget) {
      const content_url = this.data.get("table-load-url");
      this.createSidebarTable(content_url);
    }

    if (this.hasDeals_modal_xlTarget) {
      window.deals_modal_xl = new BSN.Modal(this.deals_modal_xlTarget, {
        backdrop: "static",
        keyboard: true
      });
    }

    if (this.hasDeal_partners_modal_mdTarget) {
      window.deal_partners_modal_md = new BSN.Modal(this.deal_partners_modal_mdTarget, {
        backdrop: "static",
        keyboard: true
      });
    }

    if (this.hasDeal_partner_contacts_modal_mdTarget) {
      window.deal_partner_contacts_modal_md = new BSN.Modal(this.deal_partner_contacts_modal_mdTarget, {
        backdrop: "static",
        keyboard: true
      });
    }

    if (this.hasGroup_edit_tableTarget) {
      this.createEditListTable();
    }
  }



  //
  // Actions
  //
  openNewDealModal(event) {
    event.preventDefault();
    let url = event.currentTarget.getAttribute("data-url");
    if (window.deals_modal_xl) {
      this.setModalContent(window.deals_modal_xl, url);
      super.draggable_modal('#deals_modal_xl .modal-content');
    }
  }

  openPartnerModal(event) {
    event.preventDefault();
    let url = event.currentTarget.getAttribute("data-url");
    if (window.deal_partners_modal_md) {
      this.setModalContent(window.deal_partners_modal_md, url);
      super.draggable_modal('#deal_partners_modal_md .modal-content');
    }
  }

  openEditDealModal(event) {
    event.preventDefault();
    let url = event.currentTarget.getAttribute("data-url");
    this.openLayoutModalMD(url);
  }

  openNewCloseDeal(event) {
    event.preventDefault();
    let url = event.currentTarget.getAttribute("data-url");
    this.openLayoutModal(url);
  }

  openExchangeRates(event) {
    event.preventDefault();
    let url = event.currentTarget.getAttribute("data-url");
    this.openLayoutModalMD(url);
  }

  newPartnerContact(event) {
    event.preventDefault();
    let url = event.currentTarget.getAttribute("data-url");
    let partner_id = event.currentTarget.getAttribute("partner-id");
    url = url.replace(/CONTACT_PARTNER_ID/g, partner_id);

    if (partner_id && window.deal_partner_contacts_modal_md) {
      this.setModalContent(window.deal_partner_contacts_modal_md, url)
      super.draggable_modal('#deal_partner_contacts_modal_md .modal-content');
      this.hideNewDealModal();
      this.hideDealPartnerModal();
      this.hidePartnerContactModal();
    }
  }

  showNewDealModal() {
    if (window.deals_modal_xl) {
      this.hideNewDealModal()
      this.hidePartnerContactModal()
      this.removeBackdrops();
      window.deals_modal_xl.toggle();
      window.deals_modal_xl.update();
    }
  }

  showDealPartnerModal() {
    if (window.deal_partners_modal_md) {
      this.hideDealPartnerModal()
      this.hidePartnerContactModal()
      this.removeBackdrops();
      window.deal_partners_modal_md.toggle();
      window.deal_partners_modal_md.update();
    }
  }

  hideNewDealModal() {
    if (window.deals_modal_xl) {
      window.deals_modal_xl.hide();
      window.deals_modal_xl.update();
    }
  }

  hideDealPartnerModal() {
    if (window.deal_partners_modal_md) {
      window.deal_partners_modal_md.hide();
      window.deal_partners_modal_md.update();
    }
  }

  hidePartnerContactModal() {
    if (window.deal_partner_contacts_modal_md) {
      window.deal_partner_contacts_modal_md.hide();
      window.deal_partner_contacts_modal_md.update();
    }
  }

  showHideExchangeRates(event) {
    if (this.hasExchangeRateFieldsTarget) {
      this.exchangeRateFieldsTargets.forEach((field) => {
        field.disabled = !event.currentTarget.checked;
      })
    }

    if (this.hasExchangeRateInputTarget) {
      if (event.currentTarget.checked) {
        this.exchangeRateInputTargets.forEach((field) => {
          if (field.value == "") {
            let value = field.getAttribute("data-currency-rate");
            AutoNumeric.set(field, value);
          }
          field.required = true;
          field.parentNode.parentNode.querySelector("label span.text-danger").innerHTML = "*";
        })
      } else {
        this.exchangeRateInputTargets.forEach((field) => {
          field.classList.remove("is-invalid");
          field.required = false;
          field.parentNode.parentNode.querySelector("label span.text-danger").innerHTML = "";
        })
      }
    }
  }

  groupEdit(event) {
    event.preventDefault();
    const tabulator = this.tableTarget.tabulator;
    // const selectedRows = tabulator.getSelectedRows();
    const selectedData = tabulator.getSelectedData();
    if (selectedData.length > 0) {
      let ids = []
      for (let i = 0; i < selectedData.length; i++) {
        let row = selectedData[i];
        if (Array.isArray(row.id)) ids.push(row.id[0])
        else ids.push(row.id)
      }
      const url = event.currentTarget.getAttribute("data-url");
      this.openGruopLayoutModalXL(url, ids);
    }
  }

  mappingNotCloseClick(event) {
    event.stopPropagation();
  }

  mappingOpen(event) {
    if (this.hasMapping_dropdownTarget) {
      const mapping_dropdown = this.mapping_dropdownTarget;
      new BSN.Dropdown(mapping_dropdown).toggle();
    }
  }


  filterTable() {
    this.setFilterToTable();
  }

  setSidebarFilter() {
    this.setSidebarFilterToTable();
  }


  setSidebarCurrentFilter() {
    let period_text = ""
    let status_texts = ""
    let sale_stage_texts = ""
    let product_texts = ""
    let user_texts = ""

    if (this.hasSidebar_periodTarget) {
      const items = this.getCalendarValue(this.sidebar_periodTarget);
      const fieldContainer = this.sidebar_periodTarget.closest('.form-group');
      const column_name = fieldContainer.querySelector('label').innerHTML;
      if (items.length) period_text = column_name + ": &nbsp;" + items + "<br/>"
    }

    if (this.hasSidebar_statusTarget) {
      const items = this.getSelectCollectionTexts(this.sidebar_statusTarget);
      const fieldContainer = this.sidebar_statusTarget.closest('.form-group');
      const column_name = fieldContainer.querySelector('label').innerHTML;
      if (items.length) status_texts = column_name + ": &nbsp;" + items + "<br/>"
    }

    if (this.hasSidebar_sale_stage_idsTarget) {
      const items = this.getSelectCollectionTexts(this.sidebar_sale_stage_idsTarget);
      const fieldContainer = this.sidebar_sale_stage_idsTarget.closest('.form-group');
      const column_name = fieldContainer.querySelector('label').innerHTML;
      if (items.length) sale_stage_texts = column_name + ": &nbsp;" + items + "<br/>"
    }

    if (this.hasSidebar_product_idsTarget) {
      const items = this.getSelectCollectionTexts(this.sidebar_product_idsTarget);
      const fieldContainer = this.sidebar_product_idsTarget.closest('.form-group');
      const column_name = fieldContainer.querySelector('label').innerHTML;
      if (items.length) product_texts = column_name + ": &nbsp;" + items + "<br/>"
    }

    if (this.hasSidebar_user_idsTarget) {
      const items = this.getSelectCollectionTexts(this.sidebar_user_idsTarget);
      const fieldContainer = this.sidebar_user_idsTarget.closest('.form-group');
      const column_name = fieldContainer.querySelector('label').innerHTML;
      if (items.length) user_texts = column_name + ": &nbsp;" + items + "<br/>"
    }

    const current_filter = this.sidebar_current_filterTarget;
    current_filter.innerHTML = period_text + status_texts + sale_stage_texts + product_texts + user_texts;
  }


  //
  // Main table filters
  setFilterToTable() {
    let filters = this.getPageFilters();

    if (filters.length) {
      let table = this.tableTarget.tabulator;
      table.setFilter(filters);

      let history_url = window.location.href.split("?")[0];
      let filters_url = this.convertToUrlParams(filters);

      if (filters_url.toString())
        history_url += "?" + filters_url;

      this.historyPush(history_url);
    }
  }


  // Sidebar Table Filters
  setSidebarFilterToTable() {
    let filters = this.getSidebarPageFilters();

    if (filters.length) {
      let sidebar_table = this.sidebar_tableTarget.tabulator;

      sidebar_table.setFilter(filters);
      this.setSidebarCurrentFilter();
      // close drop down
      if (this.hasSidebar_filterTarget) {
        this.sidebar_filterTarget.classList.remove('show');
      }

      let history_url = window.location.href.split("?")[0];
      let filters_url = this.convertToUrlParams(filters);

      if (filters_url.toString())
        history_url += "?" + filters_url;

      this.historyPush(history_url);
    }
  }

  getPageFilters() {
    let filters = []

    if (this.hasSearchTarget) {
      filters.push({
        field: "q",
        type: "=",
        value: this.searchTarget.value
      })
    }

    if (this.hasPeriodTarget) {
      filters.push({
        field: "period",
        type: "=",
        value: this.getCalendarValue(this.periodTarget)
      })
    }

    if (this.hasStatusTarget) {
      filters.push({
        field: "status",
        type: "=",
        value: this.getSelectCollectionValues(this.statusTarget)
      })
    }

    if (this.hasSale_stage_idsTarget) {
      filters.push({
        field: "sale_stage_ids",
        type: "=",
        value: this.getSelectCollectionValues(this.sale_stage_idsTarget)
      })
    }

    if (this.hasProduct_idsTarget) {
      filters.push({
        field: "product_ids",
        type: "=",
        value: this.getSelectCollectionValues(this.product_idsTarget)
      })
    }

    if (this.hasUser_idsTarget) {
      filters.push({
        field: "user_ids",
        type: "=",
        value: this.getSelectCollectionValues(this.user_idsTarget)
      })
    }

    return filters;
  }

  getSidebarPageFilters() {
    let filters = []

    if (this.hasSidebar_searchTarget) {
      filters.push({
        field: "q",
        type: "=",
        value: this.sidebar_searchTarget.value
      })
    }

    if (this.hasSidebar_periodTarget) {
      filters.push({
        field: "period",
        type: "=",
        value: this.getCalendarValue(this.sidebar_periodTarget)
      })
    }

    if (this.hasSidebar_statusTarget) {
      filters.push({
        field: "status",
        type: "=",
        value: this.getSelectCollectionValues(this.sidebar_statusTarget)
      })
    }

    if (this.hasSidebar_sale_stage_idsTarget) {
      filters.push({
        field: "sale_stage_ids",
        type: "=",
        value: this.getSelectCollectionValues(this.sidebar_sale_stage_idsTarget)
      })
    }

    if (this.hasSidebar_product_idsTarget) {
      filters.push({
        field: "product_ids",
        type: "=",
        value: this.getSelectCollectionValues(this.sidebar_product_idsTarget)
      })
    }

    if (this.hasSidebar_user_idsTarget) {
      filters.push({
        field: "user_ids",
        type: "=",
        value: this.getSelectCollectionValues(this.sidebar_user_idsTarget)
      })
    }

    return filters;
  }

  // Sidebar Sort
  sidebarSort(event) {
    let sort_target = event.currentTarget;
    let sort_column = sort_target.dataset.sortColumn;
    let sort_dir = sort_target.dataset.sortDir;
    let sidebar_table = this.sidebar_tableTarget.tabulator;

    // change sort dir
    if (sort_dir == "asc") sort_dir = "desc"
    else sort_dir = "asc"

    sort_target.dataset.sortDir = sort_dir

    // set sort to table
    sidebar_table.setSort(sort_column, sort_dir);

    // change links
    this.sidebar_sortTargets.forEach(el => {
      el.classList.remove("active");
      el.innerHTML = el.dataset.sortColumnName;
      // if (sort_target.querySelector("i")) sort_target.querySelector("i").remove();
    });
    sort_target.classList.add("active");
    // set new icon
    const new_html = this.getSidebarSortHtml(sort_target.dataset.sortColumnName, sort_dir);
    sort_target.innerHTML = new_html;
    if (this.hasSidebar_current_sortTarget) this.sidebar_current_sortTarget.innerHTML = new_html;
  }

  // gen icon html
  getSidebarSortHtml(column_name, sort_dir) {
    let up_or_down = "up";
    if (sort_dir == "asc") up_or_down = "down"
    const html = column_name + "&nbsp; <i class='fa-sort-alpha-" + up_or_down + " fas sort_dir'></i>"
    return html;
  }

  // Create main table
  async createTable(url) {
    const columns = this.data.get("table-columns");
    const table_target = this.tableTarget;
    const default_props = super.tabulatorDefaultProps;
    const height = document.getElementById('content').clientHeight;
    const sort_column = this.data.get("sort-column");
    const sort_dir = this.data.get("sort-dir");
    const table_id = this.data.get("table-id");
    let edit_button;

    if (this.hasGroup_edit_buttonTarget)
      edit_button = this.group_edit_buttonTarget;

    let table_props = {
      persistenceID: "deals_table" + table_id,
      index: "id",
      height: (height - 150) + "px",
      movableColumns: true,
      initialSort: [
        { column: sort_column, dir: sort_dir }
      ],
      initialFilter: this.getPageFilters(),
      // columns
      columns: JSON.parse(columns),
      // pagination
      pagination: "remote",
      paginationDataSent: {
        "size": "per_page"
      },
      paginationSize: 25,
      paginationSizeSelector: [10, 25, 50],
      // Ajax
      ajaxURL: url,
      ajaxSorting: true,
      ajaxFiltering: true,
      ajaxLoader: true,
      ajaxLoaderLoading: "<div class='loader' > Loading...</div> ",
      //
      rowFormatter: function (row) {
        const data = row.getData();
        if (data.deals__status) {
          row.getElement().classList.add("row-" + data.deals__status)
        }
      },
      rowSelectionChanged: function (data, rows) {
        if (edit_button) {
          if (data.length > 0) edit_button.classList.remove("disabled")
          else edit_button.classList.add("disabled")
        }
      }
    }

    table_props = Object.assign(default_props, table_props);
    // create table
    table_target.tabulator = new Tabulator(table_target, table_props);
    table_target.tabulator.setLocale(super.appLocale);
  }




  // create sidebar


  async createContent(content_url) {
    const content_target = document.getElementById("content");
    let content_response = await fetch(content_url);
    let content = await content_response.text();
    content_target.innerHTML = content;
    super.updateSidebarStick();

    // update title
    let content_title = content_target.querySelector("[data-show-page-title]").dataset["showPageTitle"];
    document.querySelector("title").innerText = content_title;
  }


  async createSidebarTable(url) {
    const columns = this.data.get("table-columns");
    const per_page = this.data.get("per-page");
    const table_target = this.sidebar_tableTarget;
    const sort_column = this.data.get("sort-column");
    const sort_dir = this.data.get("sort-dir");

    //
    let loadSelected = this.createContent
    let puthToTurbolinks = this.historyPush

    let table_props = {
      persistenceID: "deals_sidebar_table",
      layout: "fitDataStretch",
      headerSort: false,
      headerVisible: false,
      height: "100%",
      // columns
      columns: JSON.parse(columns),
      // sort
      initialSort: [
        { column: sort_column, dir: sort_dir }
      ],
      initialFilter: this.getSidebarPageFilters(),
      // pagination
      paginationDataSent: {
        "size": "per_page"
      },
      paginationSize: 100,
      // Ajax
      ajaxURL: url,
      ajaxSorting: true,
      ajaxFiltering: true,
      ajaxProgressiveLoad: "scroll",
      //
      rowFormatter: function (row) {
        //row - row component
        const data = row.getData();
        if (data.status) row.getElement().classList.add("row-" + data.status)
        if (data.url) row.getElement().dataset.url = data.url;
        if (data.history_url) row.getElement().dataset.history_url = data.history_url;

        if (data.is_selected) {
          row.select();
          // row.getElement().classList.add("row-active");
        }
      },
      rowClick: function (e, row) {
        const content_url = row.getElement().dataset.url;
        const history_url = row.getElement().dataset.history_url;
        loadSelected(content_url);
        let row_table = row.getTable();
        row_table.deselectRow();
        row.select();
        puthToTurbolinks(history_url);
      }

    }
    // table props
    const default_props = super.tabulatorDefaultProps
    table_props = Object.assign(default_props, table_props);
    // create table
    table_target.tabulator = new Tabulator(table_target, table_props);
    table_target.tabulator.setLocale(super.appLocale);

    this.setSidebarCurrentFilter();
  }


  createEditListTable() {
    const table_target = this.group_edit_tableTarget;
    const tabledata = JSON.parse(this.data.get("table-data"));
    const columns = JSON.parse(this.data.get("table-columns"));
    let table_props = {
      columns: columns,
      data: tabledata,
      layout: "fitDataFill",
      resizableColumns: false,
      headerSort: false,
      height: "600px"
    }
    table_target.tabulator = new Tabulator(table_target, table_props);
    // table_target.tabulator.setLocale(super.appLocale);
  }



  convertToUrlParams(filters) {
    let query_filters = {}
    filters.forEach(el => {
      if (el["value"].length)
        query_filters[el["field"]] = el["value"];
    })

    return new URLSearchParams(query_filters);
  }

  getCalendarValue(target) {
    if (target.value){
      return target.value
    } else {
      return target.dataset.period.replace("|", " — ")
    }
  }

  getSelectCollectionValues(target) {
    if (target.choices) {
      return target.choices.getValue(true)
    } else if (target.getAttribute("data-selected")) {
      return JSON.parse(target.getAttribute("data-selected"));
    } else {
      return Array.apply(null, target.selectedOptions).map(function (el) { return el.value; });
    }
  }

  getSelectCollectionTexts(target) {
    if (target.choices) {
      return target.choices.getValue().map(function (el) { return el.label });
    } else if (target.getAttribute("data-selected")) {
      let selected = Array.apply(null, JSON.parse(target.getAttribute("data-selected")));
      return Array.apply(null, JSON.parse(target.getAttribute("data-options"))).filter(function (el) { return selected.includes(el.value) }).map(function (el) { return el.label; });
    } else {
      return Array.apply(null, target.selectedOptions).map(function (el) { return el.text; });
    }
  }

  async setModalContent(modal, url) {
    // await - https://learn.javascript.ru/async-await#await
    let content_response = await fetch(url);
    let content = await content_response.text();
    //
    // https://thednp.github.io/bootstrap.native/#componentModal
    //

    if (modal) {
      this.removeBackdrops();
      modal.setContent(content);
      modal.show();
      modal.update();
    }

  }

  removeBackdrops() {
    const modalsBackdrops = document.getElementsByClassName('modal-backdrop');

    // remove every modal backdrop
    for (let i = 0; i < modalsBackdrops.length; i++) {
      document.body.removeChild(modalsBackdrops[i]);
    }
  }
}