import Choices from "choices.js";
import BSN from "bootstrap.native";
import { getMetaValue } from "helpers";
import { ApplicationController } from "../application_controller";

const TABLE_ID = "deal_extra_suppliers_form_table"
const FORM_TOTAL_IN_MAIN = "extra_suppliers_form_total_main_value"
const FORM_TOTAL_IN_ADDITIONAL = "extra_suppliers_form_total_additional_value"
const TAB_ID = "deal_services_extra_supplier"

export default class extends ApplicationController {
  static targets = [
    "service_tr",
    "save_and_new",
    "save",
    "main_form",
    "choices_supplier",
    "choices_service"
  ]

  connect() {
    // choices
    if (this.hasChoices_supplierTarget) this.initSupplierChoices();
    if (this.hasChoices_serviceTarget) this.initServiceChoices();
    //
    // Service TR
    //
    // if (this.hasService_trTarget) {
    //   this.setFormTotalHtml()
    // }

    if (this.hasMain_formTarget) {
      this.setFormTotalHtml()
      this.enableDisableSaveButtons()
    }
  }

  //
  // Actions
  //
  openModal(event) {
    event.stopImmediatePropagation();
    let url = event.currentTarget.getAttribute("data-url");
    this.openLayoutModalXL(url);
  }

  async addSelectedService(event) {
    event.preventDefault();
    const btn_target = event.currentTarget
    const url = btn_target.getAttribute("data-url")
    if (!btn_target.classList.contains("disabled")) {
      await this.addServiceRequest(url)
      this.enableDisableSaveButtons()
      this.setFormTotalHtml()
    }
  }

  statusChange(event) {
    this.setFormTotalHtml();
    event.stopImmediatePropagation();
  }

  priceChange(event) {
    const tr = event.currentTarget.closest("tr");
    this.setFormTotalHtml();
  }

  changeTrCurrency(event) {
    const tr = event.currentTarget.closest("tr");
    const currency = event.currentTarget.getAttribute("value");

    const trCurrencyText = tr.querySelectorAll(".modal_currency_text");
    trCurrencyText.forEach(el => {
      el.innerHTML = currency;
    });
    this.setFormTotalHtml();
  }

  onPostPutSuccess(event) {
    const [data, status, xhr] = event.detail;
    if (data) {
      // show notify
      window.vNotify.success({ text: data.notification_message, title: data.notification_title });


      // update tab data
      let extraSupplierBody = document.getElementById(TAB_ID)
      if (extraSupplierBody) extraSupplierBody.innerHTML = data.table_content

      // hide form modal
      if (data.save_and_new === true || data.save_and_new === "true") {
        // modal set content
        window.layout_modal_xl.setContent(data.form_content)
      } else {
        window.layout_modal_xl.hide();
      }
    }
  }

  // update deal service form
  onPostPutError(event) {
    const [data, status, xhr] = event.detail;
    // modal set content
    window.layout_modal_xl.setContent(data.form_content)
  }


  removeService(event) {
    event.preventDefault();
    event.stopImmediatePropagation();
    let response = confirm(event.currentTarget.getAttribute("data-confirm-message"));
    if (response) {
      const tr = event.currentTarget.closest("tr");
      let id = event.currentTarget.getAttribute("data-id")
      if (id) {
        let destroy_target = document.getElementById(id)
        if (destroy_target) destroy_target.value = true
      }
      tr.remove();
      this.reindexTableRows();
      this.setFormTotalHtml();
      this.enableDisableSaveButtons()
    }
  }

  //
  // private
  //

  //
  // reindex table
  //
  reindexTableRows() {
    const table = document.getElementById(TABLE_ID)
    const rows = table.tBodies[0].querySelectorAll("tr:not(.hidden)")
    for (let i = 0; i < rows.length; i++) {
      let tr = rows[i];
      const index_target = tr.querySelector("span.index")
      index_target.innerHTML = i + 1
    }
  }

  //
  // итого для формы
  //
  setFormTotalHtml() {
    const extra_form_total_main_target = document.getElementById(FORM_TOTAL_IN_MAIN)
    const extra_form_total_additional_target = document.getElementById(FORM_TOTAL_IN_ADDITIONAL)
    const totals_hash = this.calculateFormTotal();

    extra_form_total_main_target.innerHTML = super.setNumericFormat(totals_hash["main"]);
    extra_form_total_additional_target.innerHTML = super.setNumericFormat(totals_hash["additional"]);
  }

  //
  // расчет итого по строкам таблицы
  //
  calculateFormTotal() {
    const table = document.getElementById(TABLE_ID)
    const rows = table.tBodies[0].rows
    let totals_hash = {
      "main": 0,
      "additional": 0
    }
    for (let i = 0; i < rows.length; i++) {
      let tr = rows[i];
      const status_target = tr.querySelector("select.status_select")
      // не суммируем отмененые
      if (status_target.value != "canceled") {
        let row_totals_hash = this.calculateTrSum(tr);
        totals_hash["main"] += row_totals_hash["main"]
        totals_hash["additional"] += row_totals_hash["additional"]
      }
    }
    return totals_hash
  }

  //
  // calculate tr sum amount
  //
  calculateTrSum(tr) {
    const main_additional_rate = tr.getAttribute("data-main-additional-rate").replace(",", '.').replace(/[^\d.-]/g, '');
    const currency_target = tr.querySelector("ul.nav.nav-radio.nav-radio--group input[type='radio']:checked")
    const price_target = tr.querySelector("input.price_input")

    let price = parseFloat(price_target.value.replace(",", '.').replace(/[^\d.-]/g, ''))
    let to_main_rate = parseFloat(currency_target.getAttribute("data-rate-to-main").replace(",", '.').replace(/[^\d.-]/g, ''))
    let to_additional_rate = parseFloat(currency_target.getAttribute("data-rate-to-additional").replace(",", '.').replace(/[^\d.-]/g, ''))

    price = price || 0
    to_main_rate = to_main_rate || 0
    to_additional_rate = to_additional_rate || 0

    let main_total = price * to_main_rate
    let additional_total = price * to_additional_rate

    return {
      "main": main_total,
      "additional": additional_total
    }
  }

  //
  // enable_disable buttons
  //
  // TODO: move function to application.js, and replace in deal/*.js files
  // options = {
  //   save_and_new_btn_id: SAVE_AND_NEW_BTN_ID,
  //   extra_supplier_save: EXTRA_SUPPLIER_SAVE_ID,
  //   extra_supplier_empty_form_info: EXTRA_SUPPLIER_EMPTY_FORM_INFO_ID,
  // }
  // super.enableDisableSaveButtons(options)
  //
  enableDisableSaveButtons() {
    const save_and_new_btn = document.getElementById("extra_supplier_save_and_new")
    const save_btn = document.getElementById("extra_supplier_save")
    const empty_form_info = document.getElementById("extra_supplier_empty_form_info")
    const table = document.getElementById(TABLE_ID)
    const rows = table.tBodies[0].querySelectorAll("tr:not(.hidden)")
    if (rows.length > 0) {
      if (save_and_new_btn) save_and_new_btn.removeAttribute("disabled")
      save_btn.removeAttribute("disabled")
      empty_form_info.classList.add("hidden")
    } else {
      if (save_and_new_btn) save_and_new_btn.setAttribute("disabled", "disabled")
      save_btn.setAttribute("disabled", "disabled")
      empty_form_info.classList.remove("hidden")
    }
  }


  async addServiceRequest(url) {
    let service_input_id = "deal_services_add_new_service_id"
    let service_target = document.getElementById(service_input_id)
    let supplier_input_id = "deal_services_add_new_supplier_id"
    let supplier_target = document.getElementById(supplier_input_id)


    const data = new FormData();
    data.append("supplier_service_id", service_target.value);
    data.append("supplier_id", supplier_target.value);

    await fetch(url, {
      body: data,
      method: "POST",
      dataType: "text/javascript",
      credentials: "include",
      headers: {
        "X-CSRF-Token": getMetaValue("csrf-token")
      },
    }).then(function (response) {
      return response.text()
    }).then(text => {
      const table = document.getElementById(TABLE_ID)
      const rows_count = table.tBodies[0].querySelectorAll("tr:not(.hidden)").length + 1;
      let html = text.replace(/NEW_ROW_INDEX/g, rows_count)
      table.tBodies[0].insertAdjacentHTML("beforeend", html);
    })

  }

  //
  // FORM Search Choices
  //
  initSupplierChoices() {
    //
    // выбор поставщика, обновляем select для списка услуг
    //
    const target = this.choices_supplierTarget;
    const options = JSON.parse(target.dataset.options || null);
    const selected = JSON.parse(target.dataset.selected || null);

    let reload_services = this.getSupplierServicesData
    let choice = new Choices(target, {
      removeItemButton: false,
      removeItems: false,
      searchEnabled: true,
      searchChoices: true,
      searchFields: ["customProperties.cyr", "customProperties.lat"],
      searchResultLimit: 1,
      placeholder: true,
      loadingText: this.messageText("loadingText"),
      noResultsText: this.messageText("noResultsText"),
      noChoicesText: this.messageText("noChoicesText"),
      itemSelectText: this.messageText("itemSelectText")
    });
    // set as input attribute

    target.choices = choice;
    let reload_choices_id = target.getAttribute("data-reload-target-id")
    let btn_id = target.getAttribute("data-controll-btn")

    if (reload_choices_id) {
      choice.passedElement.element.addEventListener(
        "change",
        function (event) {
          const reload_el = document.getElementById(reload_choices_id)
          if (reload_el) {
            reload_services(this, event.detail.value);
          }
          const btn = document.getElementById(btn_id)
          if (btn) {
            if (event.detail.value) {
              btn.classList.remove("disabled")
            } else {
              btn.classList.add("disabled")
            }
          }
        },
        false,
      );
    }
    if (options) target.choices.setChoices(options, "value", "label", false)
    if (selected) target.choices.setChoiceByValue(selected)
    this.addValidateTrigger(target, target.choices)
  }

  initServiceChoices() {
    const placeholderValue = this.choices_serviceTarget.getAttribute("data-placeholder-value")
    let choice = new Choices(this.choices_serviceTarget, {
      placeholderValue: placeholderValue,
      removeItemButton: true,
      removeItems: true,
      searchEnabled: false,
      placeholder: true,
      loadingText: this.messageText("loadingText"),
      noResultsText: this.messageText("noResultsText"),
      noChoicesText: this.messageText("noChoicesText"),
      itemSelectText: this.messageText("itemSelectText"),
      callbackOnInit: function () {
        if (this.passedElement.element.getAttribute("data-disable") == "true") {
          this.disable();
        }
      }
    });
    this.choices_serviceTarget.choices = choice
    this.addValidateTrigger(this.choices_serviceTarget, this.choices_serviceTarget.choices)
  }

  //
  //
  async getSupplierServicesData(target, supplier_id) {
    let reload_field_id = target.getAttribute("data-reload-target-id");
    let services_url = target.getAttribute("data-services-url");
    let reload_field = document.getElementById(reload_field_id);

    if (reload_field) {

      let reload_choice = reload_field.choices;
      reload_choice.clearInput();
      reload_choice.clearStore();
      reload_choice.clearChoices();
      reload_choice.setChoiceByValue(null);

      if (supplier_id) {
        reload_choice.setChoices(async () => {
          try {
            const items = await fetch(services_url + "?supplier_id=" + supplier_id);
            return items.json();
          } catch (err) {
            // console.error(err);
          }
        },
          'value',
          'label',
          true);
        reload_choice.showDropdown();
      }
    }
  }


  messageText(key) {
    let locale = document.body.getAttribute("data-lang");
    const message_list = {
      "ru": {
        loadingText: "Загрузка...",
        noResultsText: "Ничего не найдено",
        noChoicesText: "Нет вариантов выбора",
        itemSelectText: "Нажмите для выбора"
      },
      "en": {
        loadingText: "Loading...",
        noResultsText: "No results found",
        noChoicesText: "No choices to choose from",
        itemSelectText: "Press to select"
      }
    }
    return message_list[locale][key];
  }

  addValidateTrigger(html_element, choices_object) {
    let form, controller, fieldContainer;
    form = html_element.closest('form')
    if (form != undefined)
      controller = this.formController(form)
    if (controller != undefined)
      fieldContainer = html_element.closest('.form-group');

    if (controller != undefined && fieldContainer != undefined) {
      choices_object.passedElement.element.addEventListener(
        'change',
        function (event) {
          controller.checkChoicesMulti(fieldContainer);
        },
        false,
      );
    }
  }

  formController(form_element) {
    return this.application.getControllerForElementAndIdentifier(form_element, "layout--form")
  }

}
